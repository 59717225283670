import React from 'react'
import { FormattedMessage } from 'react-intl'
import Panel from '../panel'
import { formatMoneyAmount } from '../../utils/helpers'

const LifeAnnuity = ({ property }) => {
  console.log(property)

  const lifeAnnuity = property?.attributes?.price?.life_annuity

  if (!lifeAnnuity || !lifeAnnuity?.applicable) {
    return null
  }

  const list = [
    {
      key: 'life_annuity.monthly_rent',
      value: lifeAnnuity.monthly_rent.amount,
      info: formatMoneyAmount({
        amount: lifeAnnuity.monthly_rent.amount || 0,
        currency: lifeAnnuity.monthly_rent.currency || 'EUR',
      }),
    },
    {
      key: 'life_annuity.advance',
      value: lifeAnnuity.advance.amount,
      info: formatMoneyAmount({
        amount: lifeAnnuity.advance.amount || 0,
        currency: lifeAnnuity.advance.currency || 'EUR',
      }),
    },
    {
      key: 'life_annuity.maximum_duration',
      value: lifeAnnuity.maximum_duration,
      info: (
        <>
          {lifeAnnuity.maximum_duration || 0}{' '}
          <FormattedMessage id="property_price_years" />
        </>
      ),
    },
  ]

  return (
    <Panel title={<FormattedMessage id="life_annuity" />}>
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item">
          <ul className="c-feature-list c-feature-list--striped">
            {list.map((el, index) => {
              return (
                <li key={index} className="c-feature">
                  <div className="c-feature__description">
                    <FormattedMessage id={el.key} />
                  </div>
                  <div className="c-feature__info">{el.info}</div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Panel>
  )
}

export default LifeAnnuity
